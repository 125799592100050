import { $ajax } from '../api'

// 首页概览

export default class Device {

    constructor() {}

    static device = {
      deviceScreenInfo: '/api/mo-device/device/screen/info.action',
      deviceSetParams: '/api/mo-device/device/setParam.action',
      deviceParams: '/api/mo-device/device/dictionary/params.action',
      categoryList: '/api/mo-device/device/category/list.action',          
      manufacturerList: '/api/mo-device/device/manufacturer/list.action',  
      modelList: '/api/mo-device/device/model/list.action',                
      searchGateway: '/api/mo-device/device/searchGateway.action',  
      searchDevice: '/api/mo-device/device/searchDevice.action',  
      dataDebug: '/api/mo-device/device/debugData.action',       
      deviceDebug: '/api/mo-device/device/debugSend.action',  
      refreshGateway: '/api/mo-device/device/refreshGateway.action',         
    }

    // 调试信息列表
    static dataDebug = (params) => {
      return $ajax.post(`${this.device.dataDebug}`, params).then(res => res.data)
    }

    // 调试信息列表
    static deviceDebug = (params) => {
      return $ajax.post(`${this.device.deviceDebug}`, params).then(res => res.data)
    }
    
    //搜索设备列表
    static searchDevice = (params) => {
      return $ajax.post(`${this.device.searchDevice}`, params).then(res => res.data)
    }

    //搜索网关列表
    static searchGateway = (params) => {
      return $ajax.post(`${this.device.searchGateway}`, params).then(res => res.data)
    }

    static deviceScreenInfo = (params) => {
      return $ajax.post(`${this.device.deviceScreenInfo}`, params).then(res => res.data)
    }

    // 设置设备参数
    static deviceSetParams = (params) => {
      return $ajax.post(`${this.device.deviceSetParams}`, params).then(res => res.data)
    }

    //查询设备类型
    static categoryList = (params) => {
        return $ajax.post(`${this.device.categoryList}`, params).then(res => res.data)
    }

    //查询制造商
    static manufacturerList = (params) => {
        return $ajax.post(`${this.device.manufacturerList}`, params).then(res => res.data)
    }

    //查询设备型号
    static modelList = (params) => {
        return $ajax.post(`${this.device.modelList}`, params).then(res => res.data)
    }

    // 设备参数列表
    static deviceParams = (params) => {
        return $ajax.post(`${this.device.deviceParams}`, params).then(res => res.data)
    }

    // 刷新连接网关的设备数据
    static refreshGateway = (params) => {
      return $ajax.post(`${this.device.refreshGateway}`, params).then(res => res.data)
    }
}